import logo from './logo.svg';
import './App.css';
import Landing from './components/Landing';
import Navbar from './components/Navbar'
import Services from './components/Services';
import React , {useEffect} from 'react'
import Aos from "aos"
import "aos/dist/aos.css"
import Bookappointment from './components/Bookappointment';
import Ourfacilities from './components/Ourfacilities';
import Masonry from 'masonry-layout';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Switch,
  Link
} from 'react-router-dom';
import Aboutus from './components/Aboutus';
import Specialities from './components/Specialities';
import Contactus from './components/Contactus';
import Patientguide from './components/Patientguide';
import Admin from './components/Admin';
import Updates from './components/Updates';
import Gallery from './components/Gallery';
import Doctors from './components/Doctors';
import Adminpanel from './components/Adminpanel';

function App() {



  window.onload = () => {
    const grid =  document.querySelector('.grid');
    const masonry = new Masonry(grid)
  }


  
  return (
    <>
    <Router>
    <div className="App">

   
      <Navbar />

      <Routes>
          <Route  path="/" element={[<Landing />,<Updates/>, <Services />,<Bookappointment/>]} />
          <Route  path="/Home" element={[<Landing />,<Updates/>, <Services />,<Bookappointment/>]} />
          <Route  path="/about-us" element={<Aboutus/>  } />
          <Route  path="/specialities" element={<Specialities/>  } />
          <Route  path="/contact-us" element={ <Contactus/> } />
          <Route  path="/patient-guide" element={ <Patientguide/> } />
          <Route path="/gallery" element = {<Gallery />} />
          <Route path="/admin" element = {<Admin />} />
          <Route path="/doctors" element = {<Doctors/>} />
          <Route path = "/adminpanel" element = {<Adminpanel />} />          
      </Routes>

      </div>
    </Router>


    </>
  );
}

export const hostname = 'https://172.20.10.12:3000';

export default App;
