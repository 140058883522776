import React from 'react'
import "../styles/Aboutus.css"
import doctors from "../assets/DOCBUNCH.png"
import { Helmet } from "react-helmet"; 

export default function Aboutus() {
  return (
      <div className=''>
<h1 style={{color:"#039d69"}}>
ABOUT US
<br />
<img src="https://image.ibb.co/nk616F/Layer_1_copy_21.png" width="47" height="11" align="center" color='#f49421'/>
</h1>
<article>
  <p className='p0'>
  SriSwasthik Hospitals is a 40-bed, multispecialty, tertiary care hospital with 8 plus specialties. The Hospital has been set up to offer qualitative care to all its patients in a patient-friendly environment with a focus on clinical excellence, patients’ safety & dignity, transparency & affordability and accessibility of quality care to every patient. 
Since its inception, SriSwasthik hospital has been offering world-class treatment and facilities to patients with compassion, teamwork, transparency and process-driven approach. By bringing in the best healthcare practices from across the world and by engaging the best physicians. This signifies SriSwasthik’s commitment to quality, safety and continuous improvement in the delivery of care to patients.
</p>
<Helmet>
        <html lang="en" />  
        <title>SriSwasthik Hospitals is a 40-bed, multispecialty, tertiary care hospital with 8 plus specialties</title>  
        <meta name="description" content="SriSwasthik Hospitals is a 40-bed, multispecialty, tertiary care hospital with 8 plus specialties" />  
        {/* <meta name="theme-color" content="#E6E6FA" />   */}
</Helmet>
</article>
<div className="social_icons">
  <div className="square">
    <div className="icons">
      <i className="fa fa-facebook" aria-hidden="true"></i>
    </div>
  </div>
  <div className="square">
    <div className="icons">
      <i className="fa fa-youtube" aria-hidden="true"></i>
    </div>
  </div>
  <div className="square">
    <div className="icons">
      <i className="fa fa-twitter" aria-hidden="true"></i>
    </div>
  </div>
  <div className="square">
    <div className="icons">
      <i className="fa fa-dribbble" aria-hidden="true"></i>
    </div>
  </div>
</div>
<img className='doc-img' src={doctors} alt="doctors" />
</div>
  )
}
