import React, { Component } from 'react'
import NewsTicker from "react-advanced-news-ticker"
import { hostname } from "../App";

export default class Updates extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      dataarr : [],
      data:[],
      sata:[],
      tickerData:[]
    };
  }

  componentDidMount() {
    // setTimeout(() => {
    this.fetchData()
  // }, 2000);
  }


  updateInfos = () => {
    console.log("")
  }

  fetchData = () => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");


    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers:myHeaders
    };
    
    fetch(hostname + "/api/EventsAndOffers", requestOptions)
      .then(response => response.text())
      .then(async(result) => {
        this.setState({dataset : JSON.parse(result)})

        
        
       await this.state.dataset.map((item) => {
        
       
         this.state.dataarr.push(item.description)
        this.setState({sata : this.state.dataarr})
        this.eventworker()

         });
         this.setState({data : this.state.dataarr})
       })
      .catch(error => console.log('error', error));
  }


  eventworker = () => {

   localStorage.setItem("my_ticker_data", JSON.stringify(this.state.dataarr));
   this.getTickerData()
  
  }

  getTickerData = () => {
    this.setState({tickerData : localStorage.getItem("my_ticker_data")})
  }
  

  componentDidUpdate(prevState) {
    if (prevState.tickerData !== this.state.tickerData) {
      this.fetchData()
    }
  }





  render() {

    let updates =  JSON.parse(localStorage.getItem("my_ticker_data")) && JSON.parse(localStorage.getItem("my_ticker_data")).map((item, index) => {
      
      return (  <div key={index}>
                    {item}
                </div>  )
      
    })
  

    return (
      <div style={{paddingBottom:"10%"}}>
      <h2 style={{paddingBottom:"20px", textAlign:"left", paddingLeft:"12%", color:"#09a880"}}>Events &amp; Updates</h2>
      <NewsTicker
      style={{color:"#f49421", 
          fontWeight:"bold", 
          float:"left", 
          textAlign:"left",
          paddingLeft:"12%",
          paddingRight:"12%"}}
      maxRows = {3}
      duration = {2000}
      hasMoved = {this.updateInfos}
      speed={400}
      pauseOnHover={true}
      started = {()=>{
      console.log('react advanced news ticker just started!');
      }}
      paused = {()=>{
      console.log('react advanced news ticker has been paused.');
      }}>
      
      
      
     {updates}
      {/* <div >Curabitur porttitor ante eget hendrerit adipiscing.</div>
      <div >Praesent ornare nisl lorem, ut condimentum lectus gravida ut.</div>
      <div >Nunc ultrices tortor eu massa placerat posuere.</div> */}
      </NewsTicker>
      </div>
    )
  }
}
