import React from 'react'
import { Link } from 'react-router-dom'
import Backgroundimg from "../assets/Backgroundimg2.svg"
import "../styles/Bookappointment.css"
import Contactus from './Contactus'
import { Helmet } from "react-helmet";  


export default function Bookappointment() {
  return (
    <div>
       <Helmet>  
        <html lang="en" />  
        <title>Booking an appointment to visit the doctors in op format</title>  
        <meta name="description" content="Booking an appointment to visit the doctors in op format" />   
      </Helmet>
        <div className='appointmentdiv'>
            <h1 className='mainText'>Leading The Way In</h1>
            <h1 className='mainText2'>&nbsp;<span className='mainText2' style={{color:"#f49421"}}>Medical</span> Excellence&nbsp;</h1>
            <p>
            <p className="desccription">Not just better healthcare, but a better healthcare experience. Passionate About Medicine. Compassionate About People. Serving all people through exemplary health care, education, research, and community outreach.</p>
            </p>

            <div>
            {/* <button className='appointment-button'>Book Appointment</button> */}
            <Link to="/contact-us" style={{textDecoration:'none'}} className='appointment-button'>Contact Us</Link>
            </div>
           
        </div>
    </div>
  )
}
