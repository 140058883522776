import React, { Component } from 'react'
import "../styles/Landing.css"
import LandingNurse from "../assets/Landing-Nurse.svg"
import Navbar from './Navbar'
import { Typing, TypingStep } from "typing-effect-reactjs";
import { Helmet } from "react-helmet";  

export default class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      timer:false
    };
  }

componentDidMount(){
    setInterval(() => {
      this.handleTimer()
    }, 13000);
}

handleTimer = () => {
  setTimeout(() => {
    this.setState({timer: !this.state.timer});
  }, 1000);
  
}


  render() {
    return (
      <>

      <Helmet>  
        <html lang="en" />  
        <title>HOME-Delivering high quality &amp; affordable healthcare services.</title>  
        <meta name="description" content="HOME-Delivering high quality &amp; affordable healthcare services." />  
        
      </Helmet>  

      <div className = "maxwidth">
          <div className = "Landing-page-background">
              <div>
                
                <img src={LandingNurse} className="LandingNurse" />
              </div>
              <div className='Landing-Headings'>
                <h1 className='MainText'>Delivering high quality &amp; affordable healthcare services.</h1>
                <div className='MainCaption2'>
                {this.state.timer === true ?
                  <Typing
                  className="typeWriter"
                  cursorColor="#f49421" 
                  styleClass="typeWriter"
                  disableBlinkingOnEnd={3}
                  typeSpeed={60}
                  text={[
                    "Our mission is to improve the health of our community by setting the standard of excellence in clinical care and in providing patient-centered medicine to prevent, diagnose & treat"
                  ]}
                  smartBackspace={true}
                /> : 
               null
                }

              {this.state.timer === false ?
                <Typing
                cursorColor="#f49421"
                typeSpeed={60}
                styleClass="typeWriter"
                disableBlinkingOnEnd={3}
                text={[
                  "Our Vision is to deliver high quality affordable healthcare services to the broader population in Andhra Pradesh."
                ]}
                smartBackspace={true}
              /> : 
               null
                }
                </div>
                </div>


                {/* {this.state.timer ?
                <div className='vision-mission'>
                
              
                
                <h6 className='text_1'>VISION<br/></h6>
                
                </div> :


<div className='vision-mission'>
                
                <h6 className='text_2'>MISSION</h6>
                
       
                
                </div>
  } */}


          </div>
      </div>
      </>
    )
  }
}
