    import React from 'react';
    import "../styles/contactus.css"
    import { Helmet } from "react-helmet";  


    function Contactus() {
        return (

                <div>
                     <Helmet>  
                        <html lang="en" />  
                        <title>Contact us by filling the form given or just by diling the phone number from the website</title>  
                        <meta name="description" content="Contact us by filling the form given or just by diling the phone number from the website" />  
                        
                    </Helmet>  


                                <div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3828.613600722869!2d80.4904324!3d16.342675999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35f5de62ab0da1%3A0xf3d68534dbe0600!2sSRISWASTHIK%20MULTI%20SPECIALITY%20HOSPITAL%20KAKANI!5e0!3m2!1sen!2sin!4v1668826224321!5m2!1sen!2sin" width="100%" height="300px" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe>
                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3828.613600722869!2d80.4904324!3d16.342675999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35f5de62ab0da1%3A0xf3d68534dbe0600!2sSRISWASTHIK%20MULTI%20SPECIALITY%20HOSPITAL%20KAKANI!5e0!3m2!1sen!2sin!4v1668825914497!5m2!1sen!2sin"  width="100%" height="300px" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe> */}
                                </div>
                                <div style={{backgroundColor:"rgb(238, 238, 238)"}} id="contact">
                            
                                    <div className="row p-5" style={{backgroundColor : "#f0f3f4"}}>
                                        <div className="col-md-5">
                                        </div>

                                        <div  style={{height: "50px"}}>
                                            
                                        </div>
                                        <div className="col-md-7 pt-4" >
                                            <div className="row ">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">
                                                    <h4 className="first-latter pb-1">Registered Office</h4>
                                                    <p  className='p_text'>D.No 19-54, NH-16, GNT-VJA, PEDAKAKANI MAIN ROAD, GUNTUR-522509 </p>
                                                </div>

                                                <div className="col-md-2"></div>
                                                <div className="col-md-4">
                                                    <h4 className="first-latter pb-1">Contact Details</h4>
                                                    <p className='p_text'>Ph : +91 7815 996 456</p>
                                                    <p className='p_text'>Ph : +91 6304 735 677</p>
                                                    <p className='p_text'>STD : 0863-2941 233</p>
                                                    {/* <p  style={{fontSize:"14px"}}>email : ngranga@example.com</p> */}
                                                </div>
                                            </div>


                                            
                                        </div>


                                        
                                    </div>
                                </div>

            </div>
        )
    }

    export default Contactus;