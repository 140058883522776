import React from "react";
import "../styles/Gallery.css";

import Masony from "react-masonry-component";
import InfiniteScroll from "react-infinite-scroll-component";
import { hostname } from "../App";
import { Helmet } from "react-helmet"; 

// Masory Options
const masonryOptions = {
  fitWidth: false,
  columnWidth: 300,
  gutter: 5,
  itemSelector: ".photo-item"
};

export default function Gallery() {
  const [imagesData, setImagesData] = React.useState([]);
  let [isImage, setisImage] = React.useState(false)
  let [count, setCount] = React.useState(0)

  const [imageModal, setImageModal] = React.useState({
    showModal: false,
    modalSrc: null,
    imageIndex: null,
    currentSectionLength: null
  });

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };

    fetch(hostname+"/api/Gallery", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setImagesData([...imagesData, ...res]);
        setisImage(imagesData.length)
       
      })
      .catch((err) => {});
  };

  const fetchData = () => {
    setPage(page + 1);
    getData();
  };

  const refresh = () => {
    console.log("refresh.....");
  };

  const onSet = (type) => {
    if (type == "prev") {
        
      if (imageModal.imageIndex != 0) {
        setCount(count--)
      
        let data = imagesData[imageModal.imageIndex - 1];

        setImageModal((modal) => ({
          ...modal,
          imageIndex: imageModal.imageIndex - 1,
          modalSrc: data.picture
        }));
      } else {

       
        // alert("NO MORE LEFT IMAGE");

      }
    } else if (type == "next") {
       
      if (imageModal.imageIndex > imagesData.length) {
        alert("NO MORE LEFT IMAGE");

      } else {
        setCount(imageModal.imageIndex + 1)
      

        let data = imagesData[imageModal.imageIndex + 1];

        setImageModal((modal) => ({
          ...modal,
          imageIndex: imageModal.imageIndex + 1,
          modalSrc: data.picture
        }));
      }
    }
  };

  return (
    <>

      <Helmet>  
        <html lang="en" />  
        <title>Gallery of our multi speciality hospital you can find the international standard equipment and rooms here</title>  
        <meta name="description" content="Gallery of our multi speciality hospital you can find the international standard equipment and rooms here" />  
      </Helmet>  


    <div className="">
      <InfiniteScroll
        className="containers"
        dataLength={imagesData.length}
        next={fetchData}
        // hasMore={true}
        loader={<h4>Loading...</h4>}
        // endMessage={
        //   <p style={{ textAlign: "center" }}>
        //     <b>Yay! You have seen it all</b>
        //   </p>
        // }
        refreshFunction={refresh}
        // pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8595; Pull down to refresh</h3>
        }
        releaseToRefreshContent={
          <h3 style={{ textAlign: "center" }}>&#8593; Release to refresh</h3>
        }
      >
        <Masony
          className={"photo-list"}
          elementType={"ul"}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {imagesData &&
            imagesData.map((photo, index) => (
              <li className={`photo-item`} key={index}>
                <img
                  // src={photo.picture}
                  src={`data:image/jpeg;base64,${photo.picture}`}
                  alt=""
                  onClick={() => {
                    setImageModal({
                      showModal: true,
                      modalSrc: photo.picture,
                      imageIndex: index,
                      currentSectionLength: imagesData.length
                    });
                  }}
                />
              </li>
            ))}
        </Masony>
      </InfiniteScroll>

      <div
        id="myModal"
        className="modal"
        style={{
          display: imageModal.showModal ? "block" : "none"
        }}
      >
        <div>
          <span
            className="close"
            onClick={() =>
              setImageModal((modal) => ({ ...modal, showModal: false }))
            }
          >
            &times;
          </span>

          <div
            className="mySlides"
            style={{ display: imageModal.showModal ? "block" : "none" }}
          >
            <img
              className="modal-content"
              id="img01"
              // src={imageModal.modalSrc}
              src={`data:image/jpeg;base64,${imageModal.modalSrc}`}
              
              alt=""
            />
          </div>
            {imageModal.imageIndex !== 0 ? 
          <a href="#" className="prev" onClick={() => onSet("prev")}>
            &#10094;
          </a> : null}

          {imageModal.imageIndex !== imagesData.length -1 ?
          <a href="#" className="next" onClick={() => onSet("next")}>
            &#10095;
          </a> 
          : null} 

          <div />
        </div>
      </div>
      </div>
    </>
  );
}
