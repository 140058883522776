import React, { Component } from 'react'
import "../styles/AdminDoctorsController.scss"
import { Modal, Button } from "react-bootstrap";
import {hostname} from "../App"
import { Form } from 'semantic-ui-react';
import { Helmet } from "react-helmet";  

export class AdminDoctorController extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      base64img:"",
      loading:false,
      name:"",
      age:"",
      experience:"",
      qualification:"",
      specialization:"",
      image:"",
      desc:"",
      eid:"",
    };
  }

  componentDidMount(){
    this.fetchData()
    
  }


  fetchData = () => {
    this.setState({loading:true})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");


    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    
    fetch(hostname+"/api/Doctor", requestOptions)
      .then(response => response.json())
      .then(result => this.doctorWorker(result))
      .catch(error => console.log('error', error));
  }

  doctorWorker = (result) => {
        
    this.setState({loading:false})
    this.setState({dataset : result})
 

}

  uploadProfile = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");
    myHeaders.append("Content-Type", "application/json");




var raw = JSON.stringify({
  "employeeId": parseInt(this.state.eid),
  "name": this.state.name,
  "age": parseInt(this.state.age),
  "experience": parseInt(this.state.experience),
  "specialization": this.state.specialization,
  "description": this.state.desc,
  "qualification": this.state.qualification,
  "picture": this.state.base64img
});



var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(hostname+"/api/Doctor", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result),
  this.closeModal(),

  setTimeout(() => {
    // if(result === 200){
      this.fetchData()
    // }

  }, 500)
  )
  .catch(error => console.log('error', error));


}


handleFileInputChange = e => {
      
  let { file } = this.state;

  file = e.target.files[0];

  this.getBase64(file)
    .then(result => {
      file["base64"] = result;
     
      this.setState({
        base64URL: result,
        file
      });
    })
    .catch(err => {
      console.log(err);
    });

  this.setState({
    file: e.target.files[0]
  });
};


getBase64 = file => {
  return new Promise(resolve => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
   
      var arr = reader.result.split(',');
      arr.splice(0, 1);
      

   
      baseURL = arr.join(',');
     
      this.setState({base64img : baseURL})
      resolve(baseURL); 
    };
    
  });
};


onNameChange = e => {
  let name = e.target.value
 
  this.setState({name : name})
}

onAgeChange = e => {
  let age = e.target.value
  this.setState({age : age})
}


onQualificatiomChange = e => {
  let qualification = e.target.value
  this.setState({qualification : qualification})
}


onDescChange = e => {
  let desc = e.target.value
  this.setState({desc : desc})
}


onIdChange = e => {
  let eid = e.target.value
  this.setState({eid : eid})
}

onSpecializationChange = e => {
  let specialization = e.target.value
  this.setState({specialization : specialization})
}

onExperienceChange = e => {
  let exp = e.target.value
  this.setState({experience : exp})
}

oneidChange = e => {
  let eid = e.target.value
  this.setState({eid : eid})
}




  deleteItem = (id) => {
    console.log("IDS", id)

    var myHeaders = new Headers();
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");
myHeaders.append("EmployeeId", String(id));

var requestOptions = {
  method: 'DELETE',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(hostname+"/api/Doctor", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result),
  setTimeout(() => {
    this.fetchData()
  }, 500)
  )
  .catch(error => console.log('error', error));
  }



  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });



  render() {

    let doctors = this.state.dataset.map((item, index) => {
      return(

        
     <div className="card1">
        <img src={`data:image/jpeg;base64,${item["picture"]}`} className="cardsImg" />
        <div className="card1__body">
          <h2 className="card1__title">Title: {item.name}</h2>
          <p className="card1__description"><b>Speciality:</b>{item.specialization}</p>
          <p className="card1__description"><b>qualification:</b>{item.qualification}</p>
          <p className="card1__description"><b>experience:</b>{item.experience}</p>
          <p className="card1__description"><b>age:</b>{item.age}</p>
          {/* <h3 className="card__price">Price</h3> */}
          <button className="card1__btn" onClick={()=>this.deleteItem(item["employeeId"])}>Delete</button>
        </div>

        {/* <button onClick={this.uploadImages}>
                  Upload!
                </button> */}
      </div>

      
        
       
      )
      
      
    })

    return (
      <>

      <Helmet>  
        <html lang="en" />  
        <title>Admin accessable page to update doctors information in the database</title>  
        <meta name="description" content="Admin accessable page to update doctors information in the database" />  
         
      </Helmet>  
      <div>
         {this.state.loading === true ? <h2 className='Loading'>Loading...</h2> : null}
        <div className="wrappers">
        {doctors}


        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Button className= "floating" variant="primary" onClick={this.openModal}>
            UPLOAD Profile
          </Button>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title><h3>Upload Doctors Profile</h3></Modal.Title>
          </Modal.Header>


          <Form>
                <Form.Field>
                    <div>
                    <input required id="inputs" placeholder='Employee Id' type="number" onChange={e => this.oneidChange(e)} /><br/>

                    </div>
                    <input id="inputs" placeholder='Name' type="text" onChange={e => this.onNameChange(e)} /><br/>
                    <input id="inputs" placeholder='Age' type="number" onChange={e => this.onAgeChange(e)} /><br/>
                    <input id="inputs" placeholder='Experience' type="number" onChange={e => this.onExperienceChange(e)} /><br/>
                    <input id="inputs" placeholder='Specialization' type="text" onChange={e => this.onSpecializationChange(e)} /><br/>
                    <input id="inputs" placeholder='Description' type="text" onChange={e => this.onDescChange(e)} /><br/>
                    <input id="inputs" placeholder='Qualification' type="text" onChange={e => this.onQualificatiomChange(e)} /><br/>
                    <input id="inputs" type="file" name="file" onChange={this.handleFileInputChange} />
                </Form.Field>
          </Form>
         


          
          <Modal.Footer>

          <Button  style={{backgroundColor: '#039d69', marginTop:"50px"}}  onClick={this.uploadProfile}>
                  Upload!
                </Button>

            <Button  style={{marginLeft: '100px'}} variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        </div>
      </div>
      </>

    )
  }
}

export default AdminDoctorController