import React, {useState, useEffect,} from 'react'
import { Navigate , useNavigate, Router, Routes,Route, useLocation} from 'react-router-dom';
import "../styles/Admin.css"
import Adminpanel from './Adminpanel';
import {hostname} from "../App"
import { Helmet } from "react-helmet"; 


function Admin() {
	const navigate = useNavigate();

// const [userName, setUserName] = React.useState("");
const [password, setPassword] = React.useState("");
const [loading , setLoading] = React.useState(false);
const [isLoggedin, setIsLoggedIn] = React.useState(false);
const [current, setCurrent] = React.useState("");
const [newps, setNewpswd] = React.useState("");
const [confirmps, setConfrmpswd] = React.useState("");


useEffect(() => {
 
}, [isLoggedin])



function fetchPassword(event){

	setPassword(event.target.value)

}

function onSubmit(){
	setLoading(true)

	var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");
var raw = "";

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(hostname + "/api/Users", requestOptions)
  .then(response => response.json())
  .then(result => 
	result.password === password ? 

	adminPanel(true) : alert("Enter correct password"))
  .catch(error => console.log('error', error));	
}

function adminPanel(pass = false)  {
	setIsLoggedIn(true)

if(pass === true){
	stateChange()
}
};

const stateChange = () => {
    navigate('/adminpanel', {state:{password:password,loggedin:true}});
  }

const currentpswd = (e) => {
	let ps = e.target.value
	setCurrent(ps)
}

const newpswd = (e) => {
	let ps = e.target.value
	setNewpswd(ps)
}

const confirmpswd = (e) => {
	let ps= e.target.value
	setConfrmpswd(ps)
}


const checkOldPassword = () => {
	var myHeaders = new Headers();
	myHeaders.append("Content-Type", "application/json");
	myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");

		// var raw = "";

		var requestOptions = {
		method: 'GET',
		headers: myHeaders,
		// body: raw,
		redirect: 'follow'
		};

		fetch(hostname+"/api/Users", requestOptions)
		.then(response => response.text())
		.then(result => passwordWorker(result))
		.catch(error => console.log('error', error));
}

const passwordWorker = (result) =>{
	let convert = JSON.parse(result)


	if(String(convert.password) === String(current)){
		if(newps === confirmps){
			var myHeaders = new Headers();
			myHeaders.append("Password", confirmps);
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");

			var raw = "";

			var requestOptions = {
			method: 'PUT',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
			};

			fetch(hostname+"/api/Users", requestOptions)
			.then(response => response.text())
			.then(result => console.log(result),
			alert("Success"))
			window.location.reload()
			.catch(error => console.log('error', error));
		}else{
			
			alert("New password & Confirm Password Should be same")
		}
	}else{
		console.log("FAILED")
	}
}



  return (
	<>
	  <Helmet>  
        <html lang="en" />  
        <title>Admin panel for the database updates</title>  
        <meta name="description" content="Admin panel for the database updates" />  
        {/* <meta name="theme-color" content="#E6E6FA" />   */}
      </Helmet>


	<div className="section">
		<div className="container">
			<div className="row full-height justify-content-center">
				<div className="col-12 text-center align-self-center py-5">
					<div className="section pb-5 pt-5 pt-sm-2 text-center">
						<h6 className="mb-0 pb-3"><span>Log In </span><span>Change password</span></h6>
			          	<input className="checkbox" type="checkbox" id="reg-log" name="reg-log"/>
			          	<label for="reg-log"></label>
						<div className="cardx-3d-wrap">
							<div className="cardx-3d-wrapper">
								<div className="cardx-front">
									<div className="center-wrap">
										<div className="section text-center">
											<h4 className="mb-4 pb-3" style={{color:"black"}}>Log In</h4>
											<div className="form-group">
												<input type="email" name="logemail" className="form-style" value="ADMIN" disabled id="logemail" autocomplete="off"/>
												<i className="input-icon uil uil-at"></i>
											</div>	
											<div className="form-group mt-2">
												<input  onChange={(e) => fetchPassword(e)} type="password" name="logpass" className="form-style" placeholder="Password" id="logpass" autocomplete="off"/>
												<i className="input-icon uil uil-lock-alt"></i>
											</div>
											<button onClick={() => onSubmit()} className="btn mt-4">LogIn</button>
                            				{/* <p className="mb-0 mt-4 text-center"><a href="#0" className="link">Forgot your password?</a></p> */}
				      					</div>
			      					</div>
			      				</div>
								<div className="cardx-back">
									<div className="center-wrap">
										<div className="section text-center">
											<h4 className="mb-4 pb-3" style={{color:"black"}}>change password</h4>
											<div className="form-group">
												<input onChange={e => currentpswd(e)} type="password" name="logname" className="form-style" placeholder="Current password" id="logname" autocomplete="off"/>
												<i className="input-icon uil uil-user"></i>
											</div>	
											<div className="form-group mt-2">
												<input onChange={e => newpswd(e)} type="password" name="logemail" className="form-style" placeholder="New Password" id="logemail" autocomplete="off"/>
												<i className="input-icon uil uil-at"></i>
											</div>	
											<div className="form-group mt-2">
												<input onChange={e => confirmpswd(e)} type="password" name="logpass" className="form-style" placeholder="Confirm Password" id="logpass" autocomplete="off"/>
												<i className="input-icon uil uil-lock-alt"></i>
											</div>
											<button className="btn mt-4" onClick={() => checkOldPassword()}>submit</button>
				      					</div>
			      					</div>
			      				</div>
			      			</div>
			      		</div>
			      	</div>
		      	</div>
	      	</div>
	    </div>
	</div>

	</>
  )
}

export default Admin