import React, { Component } from 'react'
import "../styles/AdminEventController.scss"
import { Modal, Button } from "react-bootstrap";
import { hostname } from '../App';
import { Helmet } from "react-helmet"; 

export class AdminEventController extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      loading:false,
      type:"",
      heading:"",
      term:"",
      date:null,
      desc:"",
      updated:false,
      isOpen:false
    };
  }



  componentDidMount() {
    this.fetchData();
  }

  fetchData() {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");


    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    
    fetch(hostname+"/api/EventsAndOffers", requestOptions)
      .then(response => response.json())
      .then(result => this.eventWorker(result))
      .catch(error => console.log('error', error));
  }


  eventWorker = (result) => {

    this.setState({loading:false})
    this.setState({dataset : result})

}


onHeadingChange = e => {
  let heading = e.target.value
  this.setState({heading : heading})
}

onTermChange = e => {
  let term = e.target.value
  this.setState({term : term})
}

onDateChange = e => {
  let date = e.target.value
  



  var start = new Date();
start.setUTCHours(0,0,0,0);

var end = new Date(date);
end.setUTCHours(23,59,59,999);



var dt = new Date(end);

var newdt = new Date(dt ).toISOString()
  this.setState({date : newdt.slice(0,19)})
}

onTypeChange = e => {
  
  let type = e.target.value
  this.setState({type : type})
}

onDescChange = e => {
  let desc = e.target.value
  this.setState({desc : desc})

}


uploadProfile = () => {


var currentTime = new Date();

var currentOffset = currentTime.getTimezoneOffset();

var ISTOffset = 330;   // IST offset UTC +5:30 

var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset)*60000);



var dt = new Date();
var off = dt.getTimezoneOffset() * 60000
var newdt = new Date(dt - off).toISOString()






var hoursIST = ISTTime.getHours()
var minutesIST = ISTTime.getMinutes()



var myHeaders = new Headers();
myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");
myHeaders.append("Content-Type", "application/json");


var raw = JSON.stringify({
  "type": this.state.type,
  "heading": this.state.heading,
  "description": this.state.desc,
  "terms": this.state.term,
  "endDate": this.state.date,
  "createDate": newdt.slice(0, 19)
});



var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(hostname+"/api/eventsandoffers", requestOptions)
  .then(async response => {
    try {
     const data = await response.text()
     
     this.closeModal()
     setTimeout(() => {
       alert("Uploaded Successfully")
       this.fetchData()
     }, 500);
    
   } catch(error) {
     
     console.error(error)
   }
  });
}

delete = (id) => {
  var myHeaders = new Headers();
myHeaders.append("id", id);
myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");

var requestOptions = {
  method: 'DELETE',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(hostname+"/api/EventsAndOffers", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result),

  setTimeout(() => {
    this.fetchData()
  }, 500),
 
  // alert("Event/Offer Deleted Successfully")

  
  )
  .catch(error => console.log('error', error));
}

openModal = () => this.setState({ isOpen: true });
closeModal = () => this.setState({ isOpen: false });


  render() {

    let events = this.state.dataset.map((item , ind) => {
  
      return(

        <div key={ind} style={{width:""}} className="card1">
        {/* <img src={`data:image/jpeg;base64,${item["picture"]}`} className="card__img" /> */}
        <div className="card1__body">
        <h4 style={{float:"left"}}><b>TYPE:&nbsp;</b>{item.heading}</h4>
        <button className='delete' onClick={() => this.delete(item.id)}>Delete</button>
        <p><b>DESCRIPTION: &nbsp;</b>{item.description} </p>

        </div>
        </div>
     
      
        
      
      )
    })
    return (
      <div className="wrappersz">

      <Helmet>  
        <html lang="en" />  
        <title>Admin accessable page to update Events information in the database</title>  
        <meta name="description" content="Admin accessable page to update Events information in the database" />  
        
      </Helmet>
      <div>
        {events}
      </div>




      <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Button className= "floating" variant="primary" onClick={this.openModal}>
            UPLOAD EVENT
          </Button>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title><h3>Upload Events &amp; Offers</h3></Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          {/* <input id="inputs" placeholder='Type' required type="text" onChange={e => this.onTypeChange(e)} /><br/> */}
          
          <div onChange={e => this.onTypeChange(e)}>
          <input type="radio" value="Event" name="Event" /> Event
          <input type="radio" value="Offer" name="Event" /> Offer
          </div>


          <input id="inputs" placeholder='Heading' type="text" onChange={e => this.onHeadingChange(e)} /><br/>
          <input id="inputs" placeholder='terms' type="text" onChange={e => this.onTermChange(e)} /><br/>
          <input id="inputs" placeholder='Description' type="text" onChange={e => this.onDescChange(e)} /><span style={{color:"orange"}}>*This will appear in HOME</span><br/>
          <input id="inputs" placeholder='endDate' type="date" onChange={e => this.onDateChange(e)} /><br/>


          
          <Modal.Footer>

          <Button  style={{backgroundColor: '#039d69', marginTop:"50px"}}  onClick={this.uploadProfile}>
                  Upload
                </Button>

            <Button  style={{marginLeft: '100px'}} variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      
      </div>
    )
  }
}

export default AdminEventController