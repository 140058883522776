import React, { Component } from 'react'
import "../styles/AdminDoctorsController.scss"
import { hostname } from '../App';
import { Helmet } from "react-helmet"; 

export default class Doctors extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dataset: [],
      base64img:"",
      loading:false,
      name:"",
      age:"",
      experience:"",
      qualification:"",
      specialization:"",
      image:"",
      desc:"",
      eid:"",
    };
  }

  componentDidMount(){
    this.fetchData()
    
  }


  fetchData = () => {
    this.setState({loading:true})
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");


    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    
    fetch(hostname+"/api/Doctor", requestOptions)
      .then(response => response.json())
      .then(result => this.doctorWorker(result))
      .catch(error => console.log('error', error));
  }

  doctorWorker = (result) => {
        
    this.setState({loading:false})
    this.setState({dataset : result})
 

}


  render() {


      
    

    let doctors = this.state.dataset.map((item, index) => {

      return(
            

        
     <div className="card1">
        <img src={`data:image/jpeg;base64,${item["picture"]}`} className="cardsImg" />
        <div className="card1__body">
          <h2 className="card1__description">Name:<span className='insideColor'>{item.name}</span></h2>
          <p className="card1__description"><b>Speciality:</b><span className='insideColor'>{item.specialization}</span></p>
          <p className="card1__description"><b>qualification:</b><span className='insideColor'>{item.qualification}</span></p>
          <p className="card1__description"><b>experience:</b><span className='insideColor'>{item.experience}</span></p>
          <p className="card1__description"><b>age:</b><span className='insideColor'>{item.age}</span></p>
        </div>
      </div>
      )
    })
    return (
   
     <>
      <Helmet>  
        <html lang="en" />  
        <title>Doctors profiles to help patient choose the specific doctor to consult</title>  
        <meta name="description" content="Doctors profiles to help patient choose the specific doctor to consult" />  
      </Helmet>  
        <div>
           {this.state.loading === true ? <h2 className='Loading'>Loading...</h2> : null}
          <div className="wrappers">
          {doctors}
          </div>
        
        </div>
      </>
      
    
    )

 
  }
}
