import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom';
import "../styles/adminpanel.css"
import { Button, Modal, Form, Input, Radio } from 'antd';
// import "antd/dist/antd.css"
import 'antd/dist/antd.min.css'
import AdminImageController from './AdminImageController';
import AdminDoctorController from './AdminDoctorController';
import AdminEventController from './AdminEventController';


function Adminpanel() {
    const location = useLocation();

    const [showimgModal, setShowimgModal] = React.useState(false)
    const [isloggedIn, setisLoggedIn] = React.useState(false)
    const [imageTab, setImageTab] = React.useState(false)
    const [eventTab, setEventTab] = React.useState(false)
    const [doctorTab, setDoctorTab] = React.useState(false)

    useEffect(() => {

        try {
            // location.state.loggedin

            if(location.state.loggedin === true){
       
                setisLoggedIn(true)
              }

        } catch (error) {
            alert("Unauthorized please login")
            window.location.replace("/home")  
        }
     
     
    }, [imageTab, eventTab, doctorTab])

    const showImageTab = () => {
        setImageTab(!imageTab)
        setEventTab(false)
        setDoctorTab(false)
      }

    const showEventsTab = () => {
        setEventTab(!eventTab)
        setImageTab(false)
        setDoctorTab(false)
      }

    const showDoctorTab = () => {
        setDoctorTab(!doctorTab)
        setImageTab(false)
        setEventTab(false)
      }

      const logOut = () => {
        alert("Success")
        window.location.replace("/home")
      }
    
  return (
    <>
    {isloggedIn === true && isloggedIn !== undefined ?
    <div style={{backgroundColor : "whitesmoke"}}>
       <button className='btns' onClick={() => showImageTab()} >Images</button>
       <button className='btns' onClick={() => showDoctorTab()}>Doctors Profile</button>
       <button className='btns' onClick={() => showEventsTab()}>Events &amp; Offers</button>
       <button className='btns' style={{float:"right", backgroundColor:"orange"}} onClick={() => logOut()}>Logout</button>
    </div> : <h4>Unauthorized please login</h4>}

    {imageTab ? <AdminImageController /> : null}
    {doctorTab ? <AdminDoctorController /> : null}
    {eventTab ? <AdminEventController /> : null}
    
    </>
  )
}

export default Adminpanel