import React from 'react'
import "../styles/Specialities.css"
import placeholder from "../assets/mainlogo.png"
import { Helmet } from "react-helmet";

export default function Specialities() {
  return (

<>

      <Helmet>  
        <html lang="en" />  
        <title>Critical care is medical care for people who have life-threatening injuries and illnesses. It usually takes place in an intensive care unit</title>  
        <meta name="description" content="Critical care is medical care for people who have life-threatening injuries and illnesses. It usually takes place in an intensive care unit" />  
       
      </Helmet>  

    <div className="container">
   <div className="row">
    <div className="col-lg-12">
       <div className="featuredPropBox">
        <ul>
           <li> <a href="#">
             <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Critical Care</h2></div>
             <div className="fptext">
              <p className='card-desc'>Critical care is medical care for people who have life-threatening injuries and illnesses. It usually takes place in an intensive care unit.</p>
            </div>
             </a> </li>
           <li> <a href="#">
           <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Dietetics and Nutrition</h2></div>             <div className="fptext">
          <p>Dietitians and nutritionists counsel clients on nutrition issues and healthy eating habits according to their personal health condition.</p>
            </div>
             </a> </li>
           <li> <a href="#">
           <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Emergency Medicine</h2></div>             <div className="fptext">
               <p>They diagnose emergency conditions and undertake acute interventions to stabilize the patient. We provide atmost care to the emergency patients.</p>
            </div>
             </a> </li>
          
          
             <li> <a href="#">
             <div className="fplogo"><h2 style={{color:"whitesmoke"}}>General Surgery</h2></div>             <div className="fptext">
                 <p>specialty where surgeons perform general surgery procedures for a wide range of common ailments and responsible for patient care before, during, and after the surgery</p>
            </div>
             </a> </li>
           <li> <a href="#">
           <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Internal Medicine and Diabetology</h2></div>             <div className="fptext">
              <p>Our Endeavour is to provide diabetic patient quality of life and our internal medicine physicians are specialist who apply scientific knowledge and clinical expertise </p>
            </div>
             </a> </li>
           <li> <a href="#">
           <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Obstetrics and Gynecology</h2></div>             <div className="fptext">
              <p>Gynecology is a medical discipline dedicated to female health care concerns including the development, diagnosis, prevention, and therapy of disorders & diseases.</p>
            </div>
             </a> </li>
          
          
             <li> <a href="#">
             <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Orthopedics</h2></div>             <div className="fptext">
                  <p>Medical specialty concerned with the preservation and restoration of function of the skeletal system and its associated structures.</p>
            </div>
             </a> </li>
           <li> <a href="#">
           <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Pediatrics</h2></div>             <div className="fptext">
                 <p>Medicine dealing with the health and medical care of infants, children, and adolescents from birth up to the age of 18.</p>
            </div>
             </a> </li>
           {/* <li> <a href="#">
           <div className="fplogo"><h2 style={{color:"whitesmoke"}}>Critical Care</h2></div>             <div className="fptext">
              <p>Dummy text is also used to demonstrate the appearance of different typefaces and layouts, and in general the content of dummy text is nonsensical.</p>
            </div>
             </a> </li> */}
             
         </ul>
      </div>
     </div>
  </div>
 </div>

</>
  )
}



// •	Critical Care
// •	Dietetics and Nutrition
// •	Emergency Medicine
// •	General Surgery
// •	Internal Medicine and Diabetology
// •	Obstetrics and Gynecology
// •	Orthopedics
// •	Pediatrics