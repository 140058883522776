import React , {useEffect} from 'react'
import Aos from "aos"
import "aos/dist/aos.css"
import "../styles/Services.css"
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

export default function Services() {

     useEffect(() => {
         Aos.init({duration : 2000});
     },[])

    

  return (
	<>

<Helmet>  
        <html lang="en" />  
        <title>Providing Services For Your Health and wellbeing</title>  
        <meta name="description" content="Providing Services For Your Health and wellbeing" />    
      </Helmet> 

    <div>
        <div className = "HeadingSection">
        <h5 className= "Section-sub-Head" data-aos="ease-in">Services For Your Health</h5>
        <h1 className= "Section-Main-Head" data-aos="ease-in">Services For Your Health</h1>
        </div>

        <div className='row cardbody'>

        <div className="circle"></div>
	<div className="container2">
		<div className="card col">
			<div className="content">
				<h2>01</h2>
				<h3 className='cardHeading'>Neurology Clinic</h3>
				<p>Neurology is the branch of medicine concerned with the study and treatment of disorders of the nervous system. The nervous system is a complex, sophisticated system that regulates and coordinates body activities.</p>
				<Link to="/specialities" className='invisible-button'>Know more</Link>
				{/* <a href="#">Read More</a> */}
			</div>
		</div>
		<div className="card col">
			<div className="content">
				<h2>02</h2>
				<h3 className='cardHeading'>Pathology Clinic</h3>
				<p>Pathology is a branch of medical science that involves the study and diagnosis of disease through the examination of surgically removed organs, tissues (biopsy samples), bodily fluids, and in some cases the whole body (autopsy).</p>
				<Link to="/specialities" className='invisible-button'>Know more</Link>
				{/* <a href="#">Read More</a> */}
			</div>
		</div>
		<div className="card col">
			<div className="content">
				<h2>03</h2>
				<h3 className='cardHeading'>Padiatric Clinic</h3>
				<p>Pediatrics is the specialty of medical science concerned with the physical, mental, and social health of children from birth to young adulthood. Pediatric care encompasses a broad spectrum of health services.</p>
				<Link to="/specialities" className='invisible-button'>Know more</Link>
				{/* <a href="#">Read More</a> */}
			</div>
		</div>
        <div className="card col">
			<div className="content">
				<h2>04</h2>
				<h3 className='cardHeading'>Cardiac Clinic</h3>
				<p>Cardiac muscle tissue is a specialized, organized type of tissue that only exists in the heart. It is responsible for keeping the heart pumping and blood circulating around the body..</p>
				<Link to="/specialities" className='invisible-button'>Know more</Link>
				{/* <a href="#">Read More</a> */}
			</div>
		</div>
	</div>
            
        </div>
    </div>
	</>
  )
}





