import React, { Component } from 'react'
import { Modal, Button } from "react-bootstrap";
import "../styles/Imagecontroller.scss"
import { Form } from 'semantic-ui-react';
import { hostname } from '../App';
import { Helmet } from "react-helmet";  


export class AdminImageController extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          dataset: [],
          isOpen: false,
          file: null,
          base64URL: "",
          base64img:"",
          imgtitle:"",
          loading:false

        };
      }

    componentDidMount(){
      this.setState({loading:true})
      this.fetchData()
    }

    fetchData = () => {
      
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");

      var requestOptions = {
          method: 'GET',
          redirect: 'follow',
          headers: myHeaders,
        };
        
        fetch(hostname+"/api/Gallery", requestOptions)
          .then(response => response.json())
          .then(result => this.imageWorker(result),
          // this.setState({loading: false})
          )
          .catch(error => console.log('error', error));
    }


    imageWorker = (result) => {
        
        this.setState({loading:false})
        this.setState({dataset : result})
     

    }

    onFiletitleChange = e => {
        let title = e.target.value
        this.setState({imgtitle : title})
    }

    handleFileInputChange = e => {
      
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then(result => {
            file["base64"] = result;
           
            this.setState({
              base64URL: result,
              file
            });
          })
          .catch(err => {
            console.log(err);
          });
    
        this.setState({
          file: e.target.files[0]
        });
      };


      getBase64 = file => {
        return new Promise(resolve => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object

            var arr = reader.result.split(',');
            arr.splice(0, 1);
            

         
            baseURL = arr.join(',');
            
            this.setState({base64img : baseURL})
            resolve(baseURL); 
          };
          
        });
      };


  


    uploadImages = () => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");
        myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "title": "images uploaded",
  "picture" : String(this.state.base64img),
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(hostname+"/api/Gallery", requestOptions)
  .then(response => response.text())
  .then(result => console.log("HII",typeof(JSON.parse(result).status)),
 
    this.closeModal(),

    setTimeout(() => {
      // if(result === 200){
        this.fetchData()
      // }
  
    }, 500)
  )
  .catch(error => console.log('error', error));
    }


 deleteItem = (id) => {
  this.setState({loading: true})
   
    var myHeaders = new Headers();
    myHeaders.append("Authorization", " B2567573-A3EF-4000-B60F-879DCA631CB3");
myHeaders.append("Id", String(id));

var requestOptions = {
  method: 'DELETE',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(hostname+"/api/Gallery", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result),
  
  setTimeout(() => {
    this.fetchData()
  }, 500)
  )
  .catch(error => console.log('error', error));

}

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

  render() {

    const images = this.state.dataset.map((item, ind) => {
        
        
        return(

            
            <div className="box">
            <img className='adminImgs' style={{float:"left", width:'200px', height:"200px"}} src={`data:image/jpeg;base64,${item["picture"]}`} alt="sample"/>
            
              <button className="delete" onClick={() => this.deleteItem(item["id"])} >{this.state.loading ? "please wait" : "Delete"}</button>
              
            </div>
          

           
    
        )
    })

    return (
      <>
      <Helmet>  
        <html lang="en" />  
        <title>Admin accessable page to update Website images information in the database</title>  
        <meta name="description" content="Admin accessable page to update Website images information in the database" />  
        
      </Helmet>
      <div>
        {this.state.loading === true ? <h2 className='Loading'>Loading...</h2> : null}
<div className="container">
        {images}
        </div>
        {/* <button onClick={this.uploadImages}>Upload Images</button> */}

        
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <Button className= "floating" variant="primary" onClick={this.openModal}>
            UPLOAD IMAGE
          </Button>
        </div>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title><h3>Choose image and Upload</h3></Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          {/* <input type="text" onChange={e => this.onFiletitleChange(e)} /> */}
          <span> <h6 style={{color:"red"}}>ONLY  ( .png )  Format accepted</h6></span>
          {/* <input placeholder='.png ONLY' type="file" name="file" style={{paddingBottom:"5%", paddingLeft:"10%"}} onChange={this.handleFileInputChange} /> */}
          <Form>
                <Form.Field>
                  
                    <input placeholder='Choose image' type="file" onChange={this.handleFileInputChange} />
                </Form.Field>
                </Form>
         

          {/* <button  onClick={this.uploadImages}>
                  Upload!
                </button> */}
          <Modal.Footer>

          <Button className="uploadg" style={{backgroundColor:"#09a880"}}  onClick={this.uploadImages}>
              Upload
            </Button>

            <Button  style={{marginLeft: '50%', }} variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      

       


      </div>
      </>
    )
  }
}

export default AdminImageController