import React, { Component } from 'react'
import "../styles/patientguide.css"
import { Helmet } from "react-helmet";

export default class Patientguide extends Component {
  render() {
    return (
      <>
      <Helmet>  
        <html lang="en" />  
        <title>Rights to Access to care, dignity, Confidentiality, safety and security</title>  
        <meta name="description" content="Rights to Access to care, dignity, Confidentiality, safety and security" />  
       
      </Helmet> 
    <div className='patient-container'>
          <h1 className='patientsHeading'>
            Patient's guide
          </h1>

        <div className=''>
        <h3 className="side-Heading">
                    Patient's Rights :
                </h3>

        </div>

          <ul className='rightList'>
              <li>Rights to Access to care</li>
              <li>Right to Respect and dignity</li>
              <li>Right to Privacy and Confidentiality</li>
              <li>Right to Personal safety and security</li>
              <li>Right to Identity</li>
              <li>Right to Information</li>
              <li>Right to Consultation</li>
              <li>Right to Transfer and Continuity of care</li>
              <li>Right to Complaint process</li>
          </ul>




          <div className=''>
        <h3 style={{paddingTop:"2%"}} className="side-Heading">
                    Patient's Responsibilities :
                </h3>

        </div>

          <ul className='resptList'>

              <li>Patient should provide complete and accurate information including patient’s full name, address, telephone numbers, age, insurance details, and other information according to the requirement of the hospital.</li>
              <li>Patient should provide accurate information about his or her health, including past illness or health problems, hospitalization, allergies and current or past use of medication.</li>
              <li>Patient should read and understand all medical forms including consent forms thoroughly before signing.</li>
              <li>Patient should follow the prescriptions and agree with the treatment plan, and comply with the instructions given.</li>
              <li>Patient should keep up the appointments made, or notify the hospital or clinic as early as possible if they are unable to keep up.</li>
              <li>Patient should not ask healthcare providers to prove incorrect information, receipts or certificates.</li>
              <li>Patients should not litter the hospital and use garbage bins to put waste. This will help the hospital authorities in keeping the hospital and its surroundings clean.</li>
              <li>No one is allowed to smoke or spit inside the hospital premises.</li>
              <li>Patients and visitors should provide useful feedback and constructive suggestions regarding the quality and extent of services available at the hospital.</li>
              <li>Patient’s caretakers should ensure that payment of the bills is made on time as per the hospital’s policy.</li>
              <li>Visitors and patients should treat the hospital staff and other patients with dignity and respect.</li>
              <li>Patients and visitors should follow the hospital rules concerning attendants, visiting hours and patient conduct.</li>
              <li>No one is allowed to damage hospital property out of frustration, anger or due to any untoward incident.</li>
              <li>Visitors and patients are refrained from bringing alcohol, drugs or weapons into the hospital.</li>
              <li>Patients and their attendants should leave their jewelry and valuables at home and bring only items necessary for the hospital stay.</li>

          </ul>
    </div>
    </>
    )
  }
}
